<template>
  <div class="bigbox tanchuang j-a">
    <div v-if="isshow" class="golist">
      <!-- 待开发 -->
      <!-- <div class="dai" @click="$router.push({name:'activeCenter'})">
        <img v-if="$route.name === 'activeCenter'" src="@/assets/images/Welfare/dai.png" alt />
        <img v-else src="@/assets/images/Welfare/dai.png" alt />
      </div>-->
      <!-- 活动中心 -->
      <div class="center" @click="$router.push({ name: 'activeCenter' })">
        <img
          v-if="$route.name === 'activeCenter'"
          src="@/assets/images/Welfare/activceCenters.png"
          alt
        />
        <img v-else src="@/assets/images/Welfare/activceCenter.png" alt />
      </div>
      <!-- 新人中心 -->
      <div class="scenter" @click="$router.push({ name: 'newwelfare' })">
        <img
          v-if="$route.name === 'newwelfare'"
          src="@/assets/images/Welfare/zonglans.png"
          alt
        />
        <img v-else src="@/assets/images/Welfare/zonglan.png" alt />
      </div>
      <!-- vip福利 -->
      <div class="new" @click="$router.push({ name: 'vipCenter' })">
        <img
          v-if="$route.name === 'vipCenter'"
          src="@/assets/images/Welfare/newwelfares.png"
          alt
        />
        <img v-else src="@/assets/images/Welfare/newwelfare.png" alt />
      </div>
      <!-- 充值福利 -->
      <div class="recharge" @click="$router.push({ name: 'rechargeWelfare' })">
        <img
          v-if="$route.name === '150cj'"
          src="@/assets/images/Welfare/rechargeWelfares.png"
          alt
        />
        <img
          v-else-if="$route.name === '200cj'"
          src="@/assets/images/Welfare/rechargeWelfares.png"
          alt
        />
        <img
          v-else-if="$route.name === '300cj'"
          src="@/assets/images/Welfare/rechargeWelfares.png"
          alt
        />
        <img
          v-else-if="$route.name === '500cj'"
          src="@/assets/images/Welfare/rechargeWelfares.png"
          alt
        />
        <img v-else src="@/assets/images/Welfare/rechargeWelfare.png" alt />
      </div>
    </div>
    <!-- 手机端 -->

    <div v-else class="mobile d-flex color-white j-a zt">
      <div
        class="t-c"
        :class="`${$route.name == 'newwelfare' ? 'fulia' : 'fuli'}`"
        @click="fuli('a')"
      >
        新人福利
      </div>
      <div
        class="fuli t-c"
        :class="$route.name == '150cj' ? 'fulia' : ''"
        @click="fuli('b')"
      >
        充值福利
      </div>
      <div
        class="fuli t-c"
        :class="$route.name == 'vipCenter' ? 'fulia' : ''"
        @click="fuli('c')"
      >
        VIP福利
      </div>
      <div
        class="fuli t-c"
        :class="$route.name == 'activeCenter' ? 'fulia' : ''"
        @click="fuli('d')"
      >
        红包福利
      </div>
    </div>

    <div class="viewbox">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isshow: true,
    };
  },
  methods: {
    fuli(vlue) {
      if (vlue == "a") {
        this.$router.push({ name: "newwelfare" });
      } else if (vlue == "b") {
        this.$router.push({ name: "rechargeWelfare" });
      } else if (vlue == "c") {
        this.$router.push({ name: "vipCenter" });
      } else if (vlue == "d") {
        this.$router.push({ name: "activeCenter" });
      }
    },
  },
  mounted() {
    if (window.innerWidth < 550) {
      this.isshow = false;
    }
  },
};
</script>

<style scoped lang="scss">
.tanchuang {
  width: 1131px !important;
  margin: auto;
  height: 506px;
  background: url("../../assets/images/home/mainallbg.png") no-repeat;
  background-size: 100% 100%;
}
.bigbox {
  width: 1500px;
  margin: auto;
  display: flex;

  .golist {
    width: 89px;
    height: 367px;
    position: relative;
    background-image: url(~@/assets/images/Welfare/welfarelist.png);
    background-size: 100% 100%;
    margin: 60px 100px;
    .center,
    .scenter,
    .dai,
    .new,
    .recharge {
      position: absolute;

      img {
        width: 178px;
      }
    }
    .center {
      top: 45px;
      left: 30px;
    }
    .scenter {
      top: 295px;
      left: 42px;
    }
    .new {
      top: 140px;
      left: 1px;
    }
    .recharge {
      top: 216px;
      left: 12px;
    }
  }
  .viewbox {
    width: 80%;
    margin: 100px 40px;
  }
}
@media (max-width: 550px) {
  .tanchuang {
    width: 100% !important;
    margin: auto;
    height: 506px;
    background: none;
  }
  .bigbox .viewbox {
    width: 80%;
    margin: auto;
  }
  .bigbox {
    width: 100% !important;
    display: block;
  }
  .golist {
    display: none;
  }
  .mobile {
    display: flex;
    justify-content: space-around;
    margin-top: 60px;
  }
  .fuli {
    width: 81px;
    height: 31px;
    line-height: 31px;

    background: url("~@/assets/images/Welfare/nav.png") no-repeat;
    background-size: 100% 100%;
  }
  .fulia {
    width: 81px;
    height: 31px;
    line-height: 31px;
    background: url("~@/assets/images/Welfare/nava.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
